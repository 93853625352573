import { Map } from "mapbox-gl";
import { Position } from "geojson";
import { UnitOfMeasurement } from "@iventis/domain-model/model/unitOfMeasurement";
import { iventisPointToAggregateLayer } from "../mapbox/engine-mapbox-helpers";
import { SupportedMapboxLayer } from "../mapbox/sublayer-types";
import { SnapIndicatorLayer } from "../generic-layers/snap-indicator-layer";

/** Mapbox layer which shows where a map object is going to snap to when drawing or editing */
export class MapboxSnapIndicatorLayer extends SnapIndicatorLayer {
    private readonly map: Map;

    private mapboxLayers: SupportedMapboxLayer[] = [];

    constructor(map: Map) {
        super();
        this.map = map;
        this.addSource();
        this.addLayer();
    }

    protected addLayer() {
        this.mapboxLayers = iventisPointToAggregateLayer(this.snapIndicatorLayer, false, { projectDataFields: [], unitOfMeasurement: UnitOfMeasurement.Metric }, null).map(
            (layer) => layer.layer
        );

        this.mapboxLayers.forEach((layer) => {
            this.map.addLayer(layer);
        });
    }

    protected removeLayer() {
        this.mapboxLayers.forEach((layer) => {
            if (this.map.getLayer(layer.id)) {
                this.map.removeLayer(layer.id);
            }
        });
    }

    protected addSource() {
        this.map.addSource(this.sourceId, { type: "geojson", data: { type: "FeatureCollection", features: this.source ? this.source : [] } });
    }

    protected removeSource() {
        if (this.map.getSource(this.sourceId)) {
            this.map.removeSource(this.sourceId);
        }
    }

    public updateSource(updatedPoint: Position | undefined) {
        super.updateSource(updatedPoint);
        const source = this.map.getSource(this.sourceId);
        if (source.type === "geojson") {
            source.setData({ type: "FeatureCollection", features: this.source });
        }
    }
}
